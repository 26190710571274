.wrap-body {
  @include wrapBody;
}

.wrap-content {
  @include wrapContent;
}

.clearfix {
  @include clearfix;
}

.margin {
  @include interpolate((margin-right, margin-left), map-get($bp, md), map-get($bp, xxl), 25px, 30px);

  &-top {
    &-half {
      @include interpolate(margin-top, map-get($bp, md), map-get($bp, xxl), 20px, 40px);
    }

    &-1 {
      @include interpolate(margin-top, map-get($bp, md), map-get($bp, xxl), 33px, 66px);
    }

    &-2 {
      @include interpolate(margin-top, map-get($bp, md), map-get($bp, xxl), 50px, 100px);
    }

    &-3 {
      @include interpolate(margin-top, map-get($bp, md), map-get($bp, xxl), 66px, 135px);
    }
  }

  &-bottom {
    &-half {
      @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 20px, 40px);
    }

    &-1 {
      @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 33px, 66px);
    }

    &-2 {
      @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 50px, 100px);
    }

    &-3 {
      @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 66px, 135px);
    }
  }
}

.padding {
  @include interpolate((padding-right, padding-left), map-get($bp, md), map-get($bp, xxl), 25px, 30px);

  &-top {
    &-half {
      @include interpolate(padding-top, map-get($bp, md), map-get($bp, xxl), 20px, 40px);
    }

    &-1 {
      @include interpolate(padding-top, map-get($bp, md), map-get($bp, xxl), 33px, 66px);
    }

    &-2 {
      @include interpolate(padding-top, map-get($bp, md), map-get($bp, xxl), 50px, 100px);
    }

    &-3 {
      @include interpolate(padding-top, map-get($bp, md), map-get($bp, xxl), 66px, 135px);
    }
  }

  &-bottom {
    &-half {
      @include interpolate(padding-bottom, map-get($bp, md), map-get($bp, xxl), 20px, 40px);
    }

    &-1 {
      @include interpolate(padding-bottom, map-get($bp, md), map-get($bp, xxl), 33px, 66px);
    }

    &-2 {
      @include interpolate(padding-bottom, map-get($bp, md), map-get($bp, xxl), 50px, 100px);
    }

    &-3 {
      @include interpolate(padding-bottom, map-get($bp, md), map-get($bp, xxl), 66px, 135px);
    }
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.block {
  display: block;
}

.none {
  display: none;
}
