.button {
  font-family: map-get($fonts, secondary);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  color: map-get($colors, brand);
  background: transparent;
  border: 2px solid map-get($colors, brand);
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;

  @include interpolate(letter-spacing, map-get($bp, sm), map-get($bp, xl), 1px, 3px);
  @include interpolate((padding-top, padding-bottom), map-get($bp, sm), map-get($bp, xl), 3px, 6px);
  @include interpolate((padding-left, padding-right), map-get($bp, sm), map-get($bp, xl), 5px, 9px);

  &.white {
    background: #fff;
    border-color: #fff;
  }

  &:hover {
    text-decoration: none;
    color: map-get($colors, brand);
  }
}

.button-icon {
  @include interpolate((width, height), map-get($bp, sm), map-get($bp, md), 16px, 24px);
}
