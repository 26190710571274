@font-face {
  font-family: "Trade Gothic W01";
  src: url("../fonts/94557603-6403-4571-a92e-e7dfa6bb6375.woff2") format("woff2"), url("../fonts/e2ec6de9-6d7f-4b65-912b-4cbe6bb5d8f4.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic W01";
  src: url("../fonts/b1979d94-a178-4b03-b43d-c8e46562f107.woff2") format("woff2"), url("../fonts/fd01e71e-6e70-4e12-b470-560c965b72b5.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic W01";
  src: url("../fonts/72b4a758-e36e-4c4f-a7bd-c9a6f0d4de92.woff2") format("woff2"), url("../fonts/845fc7e1-e021-4aea-ad51-490304a07b67.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic W02";
  src: url("../fonts/ee3d290f-f853-4ff3-913e-7bae693a43ec.woff2") format("woff2"), url("../fonts/13f010e4-8178-40a5-a790-11a6532a964c.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Trade Gothic W02";
  src: url("../fonts/dae937ec-159f-4710-9b2d-9470e24807ba.woff2") format("woff2"), url("../fonts/c8856bf2-4d23-4e30-9fb1-eede15b787b6.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
