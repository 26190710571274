.header {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 35px;
  padding-bottom: 35px;
  box-shadow: 0 5px 15px transparent;
  //transition: padding map-get($timing, duration);
  z-index: 31;

  @include mq(md, max) {
    position: absolute;
    top: 100px;
    padding-top: 25px;
    left: unset;
    padding-bottom: 25px;
    box-shadow: 0 5px 15px rgba(#000, 0.15);
  }

  @include mq(sm, max) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.is-small,
  &.is-scrolled {
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0 5px 15px rgba(#000, 0.15);

    .logo,
    .logo-icon {
      width: 181px;
      height: 45px;

      @include mq(md, max) {
        width: 120px;
        height: 30px;
      }
    }

    &.no-shadow {
      @include mq(md, min) {
        box-shadow: 0 5px 15px transparent;
      }
    }
  }

  &.is-small {
    @include mq(md, min) {
      box-shadow: 0 5px 15px transparent;
    }
  }
}

.header-holder {
  position: relative;
  width: 100%;
  height: auto;

  @include mq(md, max) {
    // height: 95px;
  }

  @include mq(sm, max) {
    // height: 64px;
  }
}

.header-inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: block;
  float: left;
}

.logo,
.logo-icon {
  width: 241px;
  height: 60px;

  @include mq(md, max) {
    width: 181px;
    height: 45px;
  }

  @include mq(sm, max) {
    width: 120px;
    height: 30px;
  }
}

.header-menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 2px 6px;
  background: rgba(#000, 0.5);
}

.header-search {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color map-get($timing, duration) * 2, background map-get($timing, duration) * 2;

  @include mq(sm, max) {
    position: absolute;
    top: 50%;
    right: 66px;
    transform: translateY(-50%);
  }

  &.is-opened {
    background: #f6f6f6;
    border-color: #ccc;

    .header-search-input-holder {
      @include interpolate(width, map-get($bp, md), map-get($bp, xxl), 200px, 300px);
    }
  }
}

.header-search-input-holder {
  width: 0;
  transition: width map-get($timing, duration) * 2;
}

.header-search-input {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 7px 12px;
  background: transparent;
  border: 0;

  &:focus {
    outline: none;
  }
}

.triggers {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.trigger {
  cursor: pointer;
  padding: 1rem;
  background: #f00;
  width: 100%;
}

.trigger-menu {
  color: #fff; // map-get($colors, brand);
  // margin-left: 0.65em;
  display: block;

  @include mq(md, max) {
    display: block;
  }

  &.is-clicked {
    .block {
      display: none;
    }

    .none {
      display: inline-block;
    }
  }

  .label {
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.trigger-icon {
  width: 24px;
  height: 24px;

  &.block {
    display: inline-block;
  }
}

.quicklinks {
  margin: 0;
  padding: 0;
  list-style: none;

  @include mq(md, max) {
    display: none;
  }
}

.quicklinks-item {
  font-size: 1.1em;
  font-weight: 700;
  line-height: 45px;
  display: inline-block;
  float: left;
  color: #fff;

  @include interpolate(margin-left, map-get($bp, sm), map-get($bp, xl), 6px, 12px);

  a {
    color: #fff;
    display: block;
    padding: 0 4px;

    &.active-height, &.active-img, &.active-set {
      border: 1px solid #fff;
    }

    &:hover {
      text-decoration: underline;
      color: #ccc;
    }
  }
}

.animcircle {
  position: fixed;
  z-index: 99;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  line-height: 1;
  font-weight: 400;
  color: #fff;
  background: map-get($colors, brand);
  border-radius: 50%;
  border: 3px solid #fff;
  padding: 30px 50px 30px 40px;
  display: flex;
  align-items: center;
  transition: transform map-get($timing, duration) * 2;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 21px, 30px);
  @include interpolate(padding-right, map-get($bp, md), map-get($bp, xxl), 30px, 40px);
  @include interpolate(padding-left, map-get($bp, md), map-get($bp, xxl), 15px, 35px);
  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxl), 130px, 200px);

  &:hover {
    text-decoration: none;
    color: #fff;
  }
}
