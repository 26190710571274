.nav {
  font-family: map-get($fonts, secondary);
  background: map-get($colors, brand);
  box-shadow: 0 5px 15px transparent;
  transition: map-get($timing, duration);
  z-index: 10;

  @include mq(md, max) {
    display: none;
  }

  &.is-static {
    position: fixed;
    top: 75px;
    right: 0;
    left: 0;

    @include mq(md, max) {
      top: 63px;
    }
  }

  &.is-fixed {
    position: fixed;
    top: 75px;
    right: 0;
    left: 0;
    box-shadow: 0 5px 15px rgba(#000, 0.3);

    & + .nav-holder {
      display: block;
    }
  }

  &.is-opposite {
    .nav-submenu {
      top: auto;
      bottom: 100%;
    }
  }
}

.nav-holder {
  position: relative;
  width: 100%;
  height: 80px;
  display: none;

  @include mq(xl, max) {
    height: 73px;
  }

  @include mq(lg, max) {
    height: 66px;
  }

  @include mq(md, max) {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;

  @include interpolate((margin-left, margin-right), map-get($bp, lg), map-get($bp, xl), -25px, -29px);

  @include mq(xl, max) {
    justify-content: center;
  }
}

.nav-item {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  float: left;

  @include interpolate((margin-left, margin-right), map-get($bp, lg), map-get($bp, xl), 25px, 30px);

  @include mq(sm, max) {
    font-size: 15px;
    width: 50%;
  }

  @include mq(xs, max) {
    font-size: 13px;
  }

  span {
    br {
      display: none;

      @include mq(sm, max) {
        display: block;
      }
    }
  }
}

.nav-link {
  color: #fff;
  line-height: 1.2;
  padding: 10px 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: relative;
    display: inline-block;
    white-space: nowrap;

    &:before,
    &:after {
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 1px;
      background: #fff;
      transition: width map-get($timing, duration) * 1.5;
      content: '';
    }

    &:after {
      left: auto;
      right: 50%;
    }
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

.nav-submenu {
  text-align: center;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;

  @include interpolate(width, map-get($bp, xxl), 2000px, 360px, 480px);
}

.nav-subitem {
  text-transform: none;
  display: block;

  &:first-child {
    margin-top: 4px;

    .nav-sublink {
      padding-top: 20px;
    }
  }

  &:last-child {
    margin-bottom: 4px;

    .nav-sublink {
      padding-bottom: 20px;
    }
  }
}

.nav-sublink {
  padding: 10px 15px;
  color: #fff;
  background: map-get($colors, brand);
  display: block;

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  span {
    position: relative;
    display: inline-block;
    white-space: nowrap;

    &:before,
    &:after {
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 1px;
      transition: width map-get($timing, duration) * 1.5;
      background: #fff;
      content: '';
    }

    &:after {
      left: auto;
      right: 50%;
    }
  }
}

.nav-item {
  &.is-hovered {
    .nav-link {
      span {
        &:before,
        &:after {
          width: 45%;
        }
      }
    }
  }
}

.nav-subitem {
  &.is-active,
  &:hover {
    span {
      &:before,
      &:after {
        width: 45%;
      }
    }
  }
}
