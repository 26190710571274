#hero-wrapper {
  margin: auto;

  &.limited {
    max-width: 1920px;
  }
}

.hero {
  #height-display {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    background-color: rgba(#fff, 0.7);
  }
}

.hero-slider-slide {
  position: relative;
  width: 100%;
  height: calc(100vh - 185px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(md, max) {
    height: calc(100vh - 95px);
  }

  @include mq(sm, max) {
    height: calc(100vh - 64px);
  }

  &.effect-2 {
    .hero-slider-bg {
      width: 150%;
    }
  }

  &.is-selected {
    .hero-slider-bg {
      transition: transform map-get($timing, duration) * 150;
    }

    &.effect-1 {
      .hero-slider-bg {
        transform: scale(1.5) translate(-10%, -10%);
      }
    }

    &.effect-2 {
      .hero-slider-bg {
        transform: translateX(-25%);
      }
    }
  }
}

.hero-slider-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  background-position: center center;
  background-size: cover;
}

.hero-slider-content {
  position: relative;
  width: 100%;
}

.hero-slider-amblem {
  line-height: 1;
  color: #fff;
  background: map-get($colors, brand);
  border-radius: 50%;
  display: flex;
  align-items: center;

  @include interpolate(font-size, map-get($bp, sm), map-get($bp, xl), 22px, 36px);
  @include interpolate(padding, map-get($bp, sm), map-get($bp, xl), 22px, 40px);
  @include interpolate((width, height), map-get($bp, sm), map-get($bp, xl),  180px, 300px);
}

.redslider {
  color: #fff;
  background: map-get($colors, brand);
  overflow: hidden;
}

.redslider-slide {
  width: 100%;
}

.redslider-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.redslider-column {
  &.left {
    width: 55%;

    @include interpolate(padding-right, map-get($bp, md), map-get($bp, xxl), 10px, 30px);

    @include mq(md, max) {
      width: 100%;
      margin-bottom: 15px;
      padding-right: 0;
    }
  }

  &.right {
    width: 45%;

    @include interpolate(padding-left, map-get($bp, md), map-get($bp, xxl), 10px, 30px);

    @include mq(md, max) {
      width: 100%;
      margin-bottom: 15px;
      padding-left: 0;
    }
  }
}

.redslider-title {
  font-weight: 700;
  line-height: 1.4;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
}

.redslider-text {
  line-height: 1.4;
  display: block;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
  @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 20px, 45px);
}

.brownslider {
  position: relative;
  background: #b0a49c;

  .button {
    margin-top: 20px;
  }

  &.v1 {
    .brownslider-shapes {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:before,
        &:after {
          position: absolute;
          right: 0;
          width: 25%;
          height: 0;
          padding-bottom: 8%;
          z-index: 1;
          content: '';

          @include mq(sm, max) {
            display: none;
          }
        }

        &:before {
          top: 0;
          background: url(../img/brownslider-shape-1.svg) top center no-repeat;
        }

        &:after {
          bottom: 0;
          background: url(../img/brownslider-shape-2.svg) top center no-repeat;
          transform: translateX(25%);
        }
      }
    }

    .brownslider-column {
      &.left {
        @include interpolate(padding-top, map-get($bp, lg), map-get($bp, xxl), 85px, 135px);

        @include mq(sm, max) {
          padding-top: 45px;
        }
      }

      &.right {
        position: relative;
      }
    }
  }

  &.v2 {
    background: #cabdb1;

    .brownslider-shapes {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:before,
        &:after {
          position: absolute;
          right: 0;
          width: 25%;
          height: 0;
          padding-bottom: 8%;
          z-index: 1;
          content: '';

          @include mq(sm, max) {
            display: none;
          }
        }

        &:before {
          top: 0;
          background: url(../img/brownslider-shape-1.svg) top center no-repeat;
        }

        &:after {
          bottom: 0;
          background: url(../img/brownslider-shape-4.svg) top center no-repeat;
          transform: translateX(25%);
        }
      }
    }

    .brownslider-shape-single {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-35%);
      width: 7%;
      height: 0;
      padding-bottom: 13%;
      background: url(../img/brownslider-shape-3.svg) top left no-repeat;
      display: none;

      @include mq(xl, max) {
        display: block;
      }

      @include mq(sm, max) {
        display: none;
      }
    }

    .brownslider-column {
      &.left {
        @include interpolate(padding-top, map-get($bp, lg), map-get($bp, xxl), 85px, 135px);

        @include mq(sm, max) {
          padding-top: 45px;
        }
      }

      &.right {
        display: none;
      }
    }

    .brownslider-quote {
      &:before,
      &:after {
        display: none;
      }
    }

    .brownslider-quote-text {
      font-style: normal;
    }
  }
}

.brownslider-slide {
  width: 100%;
}

.brownslider-columns {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;

  @include mq(sm, max) {
    flex-flow: column nowrap;
  }
}

.brownslider-column {
  &.left {
    padding-top: 66px;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background: #fff;
      content: '';

      @include interpolate(height, map-get($bp, md), map-get($bp, xxl), 33px, 66px);

      @include mq(sm, max) {
        display: none;
      }
    }
  }

  &.right {
    @include mq(sm, max) {
      display: none;
    }
  }
}

.brownslider-image {
  position: relative;
  max-width: none;

  @include interpolate(height, map-get($bp, md), map-get($bp, xxl), 280px, 500px);
  @include interpolate(margin-right, map-get($bp, md), map-get($bp, xxl), -25px, -30px);
}

.brownslider-quote {
  position: relative;

  @include interpolate(padding-left, map-get($bp, md), map-get($bp, xxl), 25px, 85px);

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    transform: translate(-25%, -25%);
    border-radius: 50%;
    content: '';

    @include interpolate((width, height), map-get($bp, md), map-get($bp, xxxl), 100px, 240px);
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    background: #fff;
    transform: translate(-75%, -50%);
    border-radius: 50%;

    @include interpolate((width, height), map-get($bp, md), map-get($bp, xxxl), 100px, 240px);
  }
}

.brownslider-quote-icon {
  position: absolute;
  left: 0;

  @include interpolate(top, map-get($bp, md), map-get($bp, xxxl), 5px, 10px);
  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxxl), 20px, 70px);
}

.brownslider-quote-text {
  font-weight: 700;
  font-style: italic;
  line-height: 1.2;
  position: relative;
  color: #6f6764;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 22px, 30px);
  @include interpolate(letter-spacing, map-get($bp, md), map-get($bp, xxl), 0.5px, 1px);
}

.brownslider-quote-pretitle {
  font-weight: 700;
  line-height: 1.4;
  position: relative;
  display: block;
  margin-bottom: 10px;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 27px, 36px);
}

.brownslider-quote-title {
  font-weight: 700;
  line-height: 1.4;
  position: relative;
  color: map-get($colors, brand);
  display: block;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
  @include interpolate(margin-top, map-get($bp, md), map-get($bp, xxl), 15px, 40px);
}

.flickity-page-dots {
  bottom: 20px;

  @include interpolate((margin-left, margin-right), map-get($bp, md), map-get($bp, xxl), -3px, -5px);

  .dot {
    width: 14px;
    height: 14px;
    background: rgba(#fff, 0.5);
    border: 3px solid transparent;
    transition: background map-get($timing, duration);
    opacity: 1;

    @include interpolate((margin-left, margin-right), map-get($bp, md), map-get($bp, xxl), 3px, 5px);
    @include interpolate(border-width, map-get($bp, md), map-get($bp, xxl), 2px, 3px);

    &.is-selected {
      border: 3px solid #fff;
      background: rgba(#000, 0.25);
    }
  }
}

.redslider,
.brownslider {
  .flickity-page-dots {
    text-align: left;
    left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);

    @include interpolate(bottom, map-get($bp, md), map-get($bp, xxl), 20px, 35px);

    @include mq(xl, max) {
      max-width: 1180px;

      @include interpolate((padding-right, padding-left), map-get($bp, md), map-get($bp, xxl), 25px, 30px);
    }

    @include mq(md, max) {
      text-align: center;
    }
  }
}
