html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;
  line-height: 1.5;
  min-height: 100vh;
  margin: 0;
  background-color: #fff;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

::-moz-selection {
  color: #fff;
  background: #4c74b7;
  text-shadow: none;
}

::selection {
  color: #fff;
  background: #4c74b7;
  text-shadow: none;
}

a {
  text-decoration: none;
  color: #4c74b7;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    color: #365383;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
figure,
input,
select,
textarea,
button {
  margin-top: 0;
  margin-bottom: 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sup,
sub {
  font-size: 65%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}

/* stylelint-disable */
h1 {font-size: 2.5em;}
h2 {font-size: 2em;}
h3 {font-size: 1.75em;}
h4 {font-size: 1.5em;}
h5 {font-size: 1.25em;}
h6 {font-size: 1em;}
/* stylelint-enable */

hr {
  width: 70%;
  margin: 5em auto;
  border-top: 0;
  border-bottom: 1px solid #ddd;
}

input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.3;
  margin: 0 0 1rem;
  padding: 1rem;
}

input,
select,
textarea {
  border: 1px solid #ddd;
}

textarea {
  resize: vertical;
}

button {
  line-height: 1.3;
  text-decoration: none;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
  color: #fff;
  background: #4c74b7;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    background: transparent;
  }
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}
