.vbcn-placeholder {
  background: rgb(221, 221, 221);
  padding: 3rem;

  .vbcn-placeholder-headline {
    //margin-top: 0;
    margin: 0 0 0.6em;
    border-bottom: 1px solid $color-text-dimmed;
    color: #333;
  }

  .vbcn-placeholder-content {
    @include vbcn-base-style;

    padding: 20px;
  }

  .vbcn-placeholder-actions {
    text-align: right;
  }

  .vbcn-text-small {
    @include vbcn-text-small;
  }
}
