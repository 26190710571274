html {
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: map-get($fonts, primary);
  color: map-get($colors, txt);
  background: map-get($colors, bckgr);

  @include interpolate(font-size, map-get($bp, sm), map-get($bp, xl), 16px, 18px);
}

main {
  margin: 4rem 0;
}

::-moz-selection {
  color: map-get($colors, selectionTxt);
  background: map-get($colors, selectionBckgr);
}

::selection {
  color: map-get($colors, selectionTxt);
  background: map-get($colors, selectionBckgr);
}

a {
  color: map-get($colors, link);

  &:hover {
    color: map-get($colors, hover);
  }
}
