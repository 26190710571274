.breadcrumbs-list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include interpolate(padding-top, map-get($bp, md), map-get($bp, xxl), 10px, 25px);
  @include clearfix;
}

.breadcrumbs-item {
  position: relative;
  display: inline-block;
  float: left;

  &:not(:first-child) {
    padding-left: 1.2em;

    &:before {
      position: absolute;
      top: -0.1em;
      left: 0.05em;
      font-size: 1.6em;
      font-weight: 700;
      line-height: 1;
      padding: 0 5px;
      content: '\203A';
    }
  }

  a {
    color: map-get($colors, txt);
    display: inline-block;

    &:hover {
      text-decoration: underline;
      color: map-get($colors, txt);
    }
  }
}

.textcols-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &.vertical {
    flex-flow: column nowrap;

    .textcols-column {
      &.left,
      &.right {
        width: 100%;
      }
    }
  }
}

.textcols-column {
  line-height: 1.4;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);

  &.left {
    width: 30%;

    @include mq(md, max) {
      width: 100%;
    }
  }

  &.right {
    width: calc(70% - 40px);

    @include mq(md, max) {
      width: 100%;
    }
  }
}

.textcols-title {
  font-weight: 700;
  line-height: 1.1;
}

h1.textcols-title {
  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 28px, 54px);
}

h2.textcols-title {
  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
}

h3.textcols-title {
  color: #8f7e72;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
}

.circles-list {
  padding: 0 15px;
  padding-top: 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.circles-item {
  position: relative;
  text-align: center;
  max-width: 33.33334%;
  flex: 0 0 33.33334%;
  color: map-get($colors, txt);
  transition: transform map-get($timing, duration) * 4;

  @include interpolate(margin-top, map-get($bp, md), map-get($bp, xxl), -50px, -90px);

  @include mq(md, max) {
    max-width: 50%;
    flex-basis: 50%;
  }

  @include mq(sm, max) {
    max-width: 100%;
    flex-basis: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.circles-icon {
  width: calc(100% + 30px);
  height: 100%;
  margin-left: -15px;
}

.circles-text {
  font-weight: 700;
  line-height: 1.4;
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 20px;
  transform: translateY(-50%);
  color: map-get($colors, txt);

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 22px, 30px);
  @include interpolate(letter-spacing, map-get($bp, md), map-get($bp, xxl), 0.5px, 1px);
  @include interpolate((padding-left, padding-right), map-get($bp, md), map-get($bp, xxl), 25px, 80px);

  &.txt-white {
    color: #fff;
  }
}

.grid-list {
  position: relative;
  margin: 0 -5px;
  display: flex;
  flex-flow: row wrap;
}

.grid-item {
  text-align: center;
  max-width: 33.33334%;
  flex: 0 0 33.33334%;
  margin-bottom: 10px;
  padding: 0 5px;
  overflow: hidden;

  @include mq(md, max) {
    max-width: 50%;
    flex-basis: 50%;
  }

  @include mq(sm, max) {
    max-width: 100%;
    flex-basis: 100%;
  }

  &.double {
    @include mq(md, min) {
      max-width: 66.66667%;
      flex-basis: 66.66667%;
    }
  }

  &:last-child {
    @include mq(md, min) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 33.33334%;
    }
  }

  &:hover {
    span {
      text-decoration: underline;
    }

    .grid-image {
      transform: scale(1.05);
    }
  }
}

.grid-holder {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  max-width: 100%;
  overflow: hidden;
}

.grid-content {
  font-weight: 700;
  line-height: 1.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
  @include interpolate(letter-spacing, map-get($bp, md), map-get($bp, xxl), 0.3px, 1px);

  &.bg-red {
    background-color: map-get($colors, brand);
  }

  &.bg-dark {
    background-color: #6f6764;
  }

  &.bg-light {
    background-color: #cabdb1;
  }

  span {
    position: relative;
  }
}

.grid-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  transition: transform map-get($timing, duration) * 4;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.25);
    content: '';
  }
}

.grid-info {
  font-weight: 400;
  text-align: left;
  line-height: 1.2;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(#000, 0.5);
  width: 100%;
  max-width: 320px;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 18px, 25px);
  @include interpolate(padding, map-get($bp, md), map-get($bp, xxl), 12px, 25px);
}

.video-holder {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  display: none;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: #eee;
  }
}

.video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  z-index: 1;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxl), 128px, 256px);
}

.textimg-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &.opposite {
    .textimg-column {
      &.image {
        padding-right: 0;
        order: 2;

        @include interpolate(padding-left, map-get($bp, md), map-get($bp, xxl), 10px, 30px);

        @include mq(md, max) {
          order: 0;
          padding-left: 0;
        }
      }

      &.text {
        padding-left: 0;

        @include interpolate(padding-right, map-get($bp, md), map-get($bp, xxl), 10px, 30px);

        @include mq(md, max) {
          padding-right: 0;
        }
      }
    }
  }
}

.textimg-column {
  &.image {
    max-width: 52%;
    flex: 0 0 52%;

    @include interpolate(padding-right, map-get($bp, md), map-get($bp, xxl), 10px, 30px);

    @include mq(md, max) {
      margin-bottom: 15px;
      padding-right: 0;
      padding-left: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  &.text {
    max-width: 48%;
    flex: 0 0 48%;

    @include interpolate(padding-left, map-get($bp, md), map-get($bp, xxl), 10px, 30px);

    @include mq(md, max) {
      padding-right: 0;
      padding-left: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.textimg-title {
  font-weight: 700;
  line-height: 1.4;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
}

.textimg-text {
  line-height: 1.4;
  margin-top: 15px;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
}

.logos {
  background: #cabdb1;
}

.logos-list {
  margin: 0 -20px;
  display: flex;
  flex-flow: row wrap;
}

.logos-item {
  max-width: 33.33334%;
  flex: 0 0 33.33334%;
  margin-bottom: 60px;
  padding: 0 30px;

  @include mq(md, max) {
    margin-bottom: 30px;
    padding: 0 15px;
    max-width: 50%;
    flex-basis: 50%;
  }

  @include mq(xs, max) {
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 10%;
  }
}

.logos-holder {
  text-align: center;
  position: relative;
  width: 84%;
  height: 0;
  margin-bottom: 16%;
  margin-left: 8%;
  padding-bottom: 84%;

  @include mq(lg, max) {
    width: 90%;
    padding-bottom: 90%;
    margin-bottom: 12%;
    margin-left: 5%;
  }

  @include mq(md, max) {
    width: 96%;
    padding-bottom: 96%;
    margin-bottom: 0;
    margin-left: 2%;
  }
}

.logos-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 24px #cabdb1, 0 0 0 30px #fff;
  overflow: hidden;

  @include interpolate(padding, map-get($bp, md), map-get($bp, xxl), 20px, 40px);

  @include mq(lg, max) {
    box-shadow: 0 0 0 14px #cabdb1, 0 0 0 20px #fff;
  }

  @include mq(md, max) {
    box-shadow: 0 0 0 7px #cabdb1, 0 0 0 10px #fff;
  }
}

.contacts-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.contacts-column {
  &.image {
    width: 26%;

    @include mq(sm, max) {
      width: 60%;
      margin: 0 auto 20px;
    }
  }

  &.text {
    line-height: 1.2;
    width: 74%;
    padding-top: 15px;

    @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 17px, 24px);
    @include interpolate(padding-left, map-get($bp, md), map-get($bp, xxl), 60px, 120px);

    @include mq(md, max) {
      padding-top: 10px;
    }

    @include mq(sm, max) {
      width: 100%;
      padding: 0;
    }

    p {
      margin: 0;

      &:not(:last-child) {
        @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 15px, 30px);
      }
    }

    a {
      text-decoration: underline;
      color: map-get($colors, txt);

      &:hover {
        text-decoration: none;
        color: map-get($colors, txt);
      }
    }
  }
}

.contacts-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  @include mq(sm, max) {
    margin-bottom: 30px;
  }
}

.contacts-image-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 20px 30px;
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 0 16px #fff, 0 0 0 20px #8f7e72;

  @include mq(lg, max) {
    margin: 15px;
    box-shadow: 0 0 0 12px #fff, 0 0 0 15px #8f7e72;
  }

  @include mq(md, max) {
    margin: 10px;
    box-shadow: 0 0 0 8px #fff, 0 0 0 10px #8f7e72;
  }

  @include mq(sm, max) {
    margin: 15px 20px 15px 0;
  }
}

.contacts-name {
  font-weight: 700;
  line-height: 1.4;
  color: #8f7e72;
  margin-bottom: 8px;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
}

.contacts-function {
  line-height: 1.4;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
  @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 15px, 30px);
}

.accordions-title {
  font-weight: 700;
  line-height: 1.4;
  color: map-get($colors, brand);

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);
  @include interpolate(margin-bottom, map-get($bp, md), map-get($bp, xxl), 20px, 40px);
}

.accordions-item {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
  padding: 15px 0;
  border-bottom: 1px solid #000;
  display: flex;
  flex-flow: column nowrap;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 16px, 20px);
}

.accordions-item-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.accordions-item-name {
  color: #000;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.has-desc {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &.is-clicked {
      .block {
        display: none;
      }

      .none {
        display: block;
      }
    }
  }
}

.accordions-item-icon {
  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxl), 16px, 20px);
  @include interpolate(margin-right, map-get($bp, md), map-get($bp, xxl), 6px, 12px);
}

.accordions-icon {
  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxl), 16px, 20px);
}

.accordions-item-link {
  line-height: 1.6;
  color: map-get($colors, brand);
  margin-left: 30px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 16px, 20px);

  &:hover {
    text-decoration: none;
    color: map-get($colors, brand);
  }

  span {
    @include interpolate(margin-right, map-get($bp, md), map-get($bp, xxl), 8px, 16px);
  }
}

.accordions-item-desc {
  font-family: map-get($fonts, secondary);
  width: 100%;
  display: none;
  font-weight: 300;
  font-size: 1em;

  &-inner {
    padding: 30px;

    @include interpolate((padding-left, padding-right), map-get($bp, md), map-get($bp, xxl), 20px, 100px);
  }
}

.caccordions-title {
  cursor: pointer;
  font-weight: 700;
  line-height: 1.4;
  color: map-get($colors, brand);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;

  @include interpolate(font-size, map-get($bp, md), map-get($bp, xxl), 20px, 26px);

  &.is-clicked {
    .block {
      display: none;
    }

    .none {
      display: block;
    }
  }
}

.caccordions-title-icon {
  @include interpolate(margin-right, map-get($bp, md), map-get($bp, xxl), 6px, 12px);
  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxl), 22px, 30px);
}

.caccordions-content {
  display: none;

  .padding {
    padding-top: 30px;
    padding-right: 0;
    padding-left: 0;
  }
}
