// Colors
$colors: (
  brand: #b6161b,
  txt: #444,
  bckgr: #fff,
  link: #4c74b7,
  hover: #34558c,
  selectionTxt: #fff,
  selectionBckgr: #4c74b7
);

// Breakpoints
$bp: (
  xs: 384px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

// Animation
$timing: (
  duration: 200ms,
  delay: 200ms
);

// Fonts
$fonts: (
  primary: #{sans-serif},
  secondary: #{sans-serif},
);
