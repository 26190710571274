// Wrap body
@mixin wrapBody {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1920px;
  display: block;
}

// Wrap content
@mixin wrapContent {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  display: block;
}

/* stylelint-disable */
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
/* stylelint-enable */

// Clearfix
@mixin clearfix {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}
