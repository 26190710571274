.map {
  position: relative;
  width: 100%;
  background-color: #eee;
  background-position: center center;
  background-size: cover;

  @include interpolate(height, map-get($bp, sm), map-get($bp, xl), 300px, 500px);
}

.prefooter {
  color: #fff;
  background: #6f6764;
}

.prefooter-inner {
  position: relative;
}

.prefooter-columns {
  margin: 0 -20px;
  display: flex;
  flex-flow: row wrap;
}

.prefooter-column {
  max-width: 25%;
  flex: 0 0 25%;
  padding: 0 20px;

  @include interpolate(margin-bottom, map-get($bp, sm), map-get($bp, xl), 50px, 100px);

  @include mq(lg, max) {
    max-width: 50%;
    flex-basis: 50%;
  }

  @include mq(sm, max) {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.prefooter-title {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5em;
}

.prefooter-links {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    font-size: 1em;
    display: block;

    @include mq(md, max) {
      font-size: 0.9em;
    }
  }

  a {
    color: #fff;
    display: block;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
}

.footer {
  color: #fff;
  background: #6f6764;
}

.footer-columns {
  margin: 0 -20px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

.footer-column {
  max-width: 25%;
  flex: 0 0 25%;
  padding: 0 20px;

  @include interpolate(margin-bottom, map-get($bp, sm), map-get($bp, xl), 50px, 100px);
}

.footer-title {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5em;
}

.footer-text {
  font-size: 1em;

  @include mq(md, max) {
    font-size: 0.9em;
  }
}

.to-top {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  color: #fff;
  background: map-get($colors, brand);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxl), 30px, 44px);
}

.to-top-icon {
  @include interpolate((width, height), map-get($bp, md), map-get($bp, xxl), 16px, 20px);
  @include interpolate(margin, map-get($bp, md), map-get($bp, xxl), 7px, 12px);
}
