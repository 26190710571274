// Fluid sizing
// Usage: @include interpolate(font-size, 320px, 1366px, 14px, 20px);
// Usage: @include interpolate((padding-top, padding-bottom), 20rem, 70rem, 0rem, .5rem);
@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: "+";

  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }

  @return calc(#{$a*100}vw #{$sign} #{$b});
}

@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

// Media Queries
// Usage: @include mq(xs, max || min, width || height) {}
// Usage: @include mq(500px, max || min, width || height) {}
@mixin mq($value, $type: max, $dir: width) {
  @if map_has_key($bp, $value) {
    $value: map_get($bp, $value);
  }

  @else {
    $value: $value;
  }

  @if $type == min {
    $value: $value + 1;
  }

  @media (#{$type}-$dir: $value) {
    @content;
  }
}

// Form placeholder color mixin
/* stylelint-disable */
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
/* stylelint-enable */
