.nav-mobile {
  font-family: map-get($fonts, secondary);
  position: fixed;
  right: 0;
  bottom: 64px;
  color: #fff;
  overflow-y: auto;
  width: 200px;
  z-index: 100;

  input, select {
    padding: 0.5rem;
  }

  @include mq(md, min) {
    // display: none !important; /* stylelint-disable-line declaration-no-important */
    //position: relative;
  }

  @include mq(md, max) {
    position: relative;
    // top: 64px;
  }

  &.is-scrolled {
    // top: 64px;
  }
}

.nav-mobile-inner {
  display: none;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  background: rgba(#000, 0.5);
}

.nav-mobile-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-mobile-item {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  position: relative;
  display: block;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.has-submenu {
    .nav-mobile-link {
      padding-right: 35px;
    }
  }

  &.is-clicked {
    .nav-mobile-link {
      span {
        &:after {
          width: 100%;
        }
      }
    }

    .nav-mobile-icon {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.nav-mobile-icon {
  position: absolute;
  right: 0;
  cursor: pointer;

  @include interpolate(top, map-get($bp, sm), map-get($bp, xl), -3px, 3px);

  svg {
    padding: 3px;
    transition: transform map-get($timing, duration) * 1.5;
  }
}

.nav-mobile-link {
  color: #fff;
  display: block;

  span {
    position: relative;

    &:after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background: #fff;
      transition: width map-get($timing, duration) * 1.5;
      content: '';
    }
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

.nav-mobile-submenu {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
  display: none;
}

.nav-mobile-subitem {
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
  display: block;

  &.is-active {
    span {
      &:after {
        width: 100%;
      }
    }
  }
}

.nav-mobile-sublink {
  color: #fff;
  padding: 3px 0 3px 20px;
  display: block;

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  span {
    position: relative;

    &:after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      transition: width map-get($timing, duration) * 1.5;
      background: #fff;
      content: '';
    }
  }
}

.nav-mobile-quicklinks {
  margin: 25px 0 0;
  padding: 15px 0 0;
  list-style: none;
  border-top: 1px solid #fff;
}

.nav-mobile-quicklinks-item {
  font-weight: 700;
  display: block;

  a {
    color: #fff;
    padding: 4px;
    display: block;

    &.active-height, &.active-img, &.active-set {
      border: 1px solid #fff;
    }
  }
}
