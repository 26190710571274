.gallery-list {
  max-width: 1200px;

  @include clearfix;
}

.gallery-item {
  float: left;
  width: 16.66667%;
  height: 190px;
  margin: 0;
  background-color: #ccc;
  background-position: center center;
  background-size: cover;
  border: 1px solid #fff;

  @include mq(xl, max) {
    height: 155px;
  }

  @include mq(lg, max) {
    width: 25%;
    height: 220px;
  }

  @include mq(md, max) {
    height: 175px;
  }

  @include mq(sm, max) {
    height: 125px;
  }

  @include mq(xs, max) {
    width: 50%;
  }
}

.gallery-item-double {
  width: 33.33334%;
  height: 380px;

  @include mq(xl, max) {
    height: 310px;
  }

  @include mq(lg, max) {
    width: 50%;
    height: 440px;
  }

  @include mq(md, max) {
    height: 350px;
  }

  @include mq(sm, max) {
    height: 250px;
  }

  @include mq(xs, max) {
    width: 100%;
  }
}
